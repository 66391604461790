import UserProvider, { UserContext } from 'context/UserContext';
import { useContext } from 'react';
// Router & Okta
import { Redirect, Route, RouteProps, Switch, useHistory } from 'react-router';
import { BrowserRouter as Router } from 'react-router-dom';
import { OktaAuth } from '@okta/okta-auth-js';
import { Security, SecureRoute, LoginCallback } from '@okta/okta-react';
import config from 'config';
// Context
import EditProvider from 'context/EditContext';
//
// API queries
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';

// Screens
import HomeScreen from 'screens/HomeScreen';
import MyBusinessScreen from 'screens/MyBusinessScreen';
import MediaScreen from 'screens/MediaScreen';
import TeamScreen from 'screens/TeamScreen';
import PagesScreen from 'screens/PagesScreen';
import PartnersScreen from 'screens/PartnersScreen';
import CreatePartnerScreen from 'screens/CreatePartnerScreen';
import BlogScreen from 'screens/BlogScreen';
import LoginScreen from 'screens/LoginScreen';
import PageEditScreen from 'screens/PageEditScreen';
import BlogEditScreen from 'screens/BlogEditScreen';
import AdminBusinessScreen from 'screens/AdminBusinessScreen';
import AdminApprovalScreen from 'screens/AdminApprovalScreen';
import AddPageScreen from 'screens/AddPageScreen';
import AwardsScreen from 'screens/AwardsScreen';
import CreateAwardScreen from 'screens/CreateAwardScreen';
import PartnerEditScreen from 'screens/PartnerEditScreen';
import AwardEditScreen from 'screens/AwardEditScreen';
import { NotFoundPage } from 'screens/NotFoundPage';
import { Unauthorized } from 'screens/Unauthorised';
import BusinessNotFoundScreen from 'screens/BusinessNotFoundScreen';
import ActivityScreen from 'screens/ActivityScreen';
import RedirectManagementScreen from 'screens/RedirectManagementScreen';

const oktaAuth = new OktaAuth(config);
const queryClient = new QueryClient();

const AdminRoute = ({ path, component }: RouteProps) => {
  const { user } = useContext(UserContext);
  if (!user.corporateUser) {
    return <Redirect to='/' />;
  }
  return <SecureRoute path={path} component={component} />;
};

const AuthorisedRoute = ({ path, component }: RouteProps) => {
  const { user } = useContext(UserContext);
  if (user) {
    return <SecureRoute path={path} component={component} />;
  }
  return <Redirect to='/unauthorised' />;
};

const HasAccessToRouter = () => {
  const history = useHistory();
  const customAuthHandler = () => {
    history.push(`/login`);
  };

  return (
    <Security oktaAuth={oktaAuth} onAuthRequired={customAuthHandler} restoreOriginalUri={() => {}}>
      <Switch>
        <Route path='/login' exact component={LoginScreen} />
        <Route path='/implicit/callback' component={LoginCallback} />
        <AuthorisedRoute path='/admin/businesses' component={AdminBusinessScreen} />
        <AdminRoute path='/admin/approvals' component={AdminApprovalScreen} />
        <Route path='/unauthorised' component={Unauthorized} />
        <AuthorisedRoute path='/business-not-found' component={BusinessNotFoundScreen} />
        <AuthorisedRoute path='/:businessName' exact component={HomeScreen} />
        <AuthorisedRoute path='/:businessName/my-business' component={MyBusinessScreen} />
        <AuthorisedRoute path='/:businessName/blog-edit/:slug' component={BlogEditScreen} />
        <AuthorisedRoute path='/:businessName/blog-create' component={() => <BlogEditScreen adding={true} />} />
        <AuthorisedRoute path='/:businessName/page-edit/:slug+' component={PageEditScreen} />
        <AuthorisedRoute path='/:businessName/page-create/:slug' exact component={AddPageScreen} />
        <AuthorisedRoute path='/:businessName/awards' component={AwardsScreen} />
        <AuthorisedRoute path='/:businessName/pages' component={PagesScreen} />
        <AuthorisedRoute path='/:businessName/team' component={TeamScreen} />
        <AuthorisedRoute path='/:businessName/blog-listing' component={BlogScreen} />
        <AuthorisedRoute path='/:businessName/partners' component={PartnersScreen} />
        <AuthorisedRoute path='/:businessName/partner-edit/:id' component={PartnerEditScreen} />
        <AuthorisedRoute path='/:businessName/award-edit/:id' component={AwardEditScreen} />
        <AuthorisedRoute path='/:businessName/partner-create' component={CreatePartnerScreen} />
        <AuthorisedRoute path='/:businessName/award-create' component={CreateAwardScreen} />
        <AuthorisedRoute path='/:businessName/media' component={MediaScreen} />
        <AuthorisedRoute path='/:businessName/activity' component={ActivityScreen} />
        <AuthorisedRoute path='/admin/redirects' component={RedirectManagementScreen}/>

        <AuthorisedRoute path='*' component={NotFoundPage} />
      </Switch>
    </Security>
  );
};

function App() {
  return (
    <Router>
      <UserProvider>
        <EditProvider>
          <QueryClientProvider client={queryClient}>
            <HasAccessToRouter />
            <ReactQueryDevtools initialIsOpen={false} />
          </QueryClientProvider>
        </EditProvider>
      </UserProvider>
    </Router>
  );
}

export default App;
