import React, { useState, useContext } from 'react';
import { ChangeSocial } from 'commands/business/social/ChangeSocial';
import { CommandBatch, ICommand } from 'commands/Commands';

import { EditContext } from 'context/EditContext';

import BusinessEditControls from 'components/MyBusiness/BusinessEditControls';

import InputModal from 'components/Modals/InputModal';

type Props<TCommand extends ICommand> = {
  initialVal?: string;
  label: string;
  commands: CommandBatch;
  showControls?: boolean;
  commandFactory: (payload: string) => TCommand;
};
const BusinessTextAreaModal = ({ initialVal, label, commands, showControls = true, commandFactory }: Props<ChangeSocial>) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const [value, setValue] = useState<string>(initialVal ? initialVal : '');
  const [savedValue, setSavedValue] = useState<string>(initialVal ? initialVal : '');
  const { setDirty } = useContext(EditContext);
  const handleSend = () => {
    setSavedValue(value);
    commands.add(commandFactory(value));
    setIsEditing(false);
    setDirty(true);
  };

  return (
    <>
      <div className='flex justify-between mx-5 mt-2 mb-3 content-center'>
        <h3 className='block text-lg text-gray-700 p-1'>{label}:</h3>
        <div className='flex'>
          {isEditing ? (
            <InputModal
              saveText={'Save'}
              title={label}
              description={`set ${label}`}
              open={isEditing}
              inputValue={value}
              textArea={true}
              setInputValue={setValue}
              sendHandler={handleSend}
              noValidation={true}
              onClose={() => setIsEditing(false)}
            />
          ) : (
            <p className='px-3 pt-2'>{savedValue}</p>
          )}
          {showControls ? <BusinessEditControls isEditing={isEditing} setIsEditing={setIsEditing} /> : null}
        </div>
      </div>
    </>
  );
};

export default BusinessTextAreaModal;
