import { Dialog, Transition } from '@headlessui/react';
import React, { Fragment, useEffect, useRef, useState } from 'react';

type ModalProps = {
  title: string;
  description: string;
  inputValue: string;
  setInputValue: (value: string) => void;
  sendHandler: () => void;
  onClose: () => void;
  textArea?: boolean;
  saveText: string;
  open: boolean;
  noValidation?: boolean;
  customValidation?: (value: string) => Promise<boolean | undefined>;
  red?: boolean;
};

const InputModal = (props: ModalProps) => {
  const cancelButtonRef = useRef<HTMLDivElement>(null);

  const validation = props.noValidation
    ? async () => new Promise<boolean>(resolve => resolve(false))
    : props.customValidation ?? (async (value: string) => new Promise<boolean>(resolve => resolve(value === '')));

  const [notValid, setNotValid] = useState<boolean | undefined>(false);
  useEffect(() => {
    validation(props.inputValue).then(setNotValid);
  }, []);

  const onValueChange = async (event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const value = event.target.value;
    props.setInputValue(value);
    console.log(await validation(value));
    setNotValid(await validation(value));
  };

  return (
    <Transition show={props.open} as={Fragment}>
      <Dialog as='div' className='fixed inset-0 z-10 overflow-y-auto' initialFocus={cancelButtonRef} static open={props.open} onClose={props.onClose}>
        <div className='min-h-screen px-4 text-center'>
          <Dialog.Overlay className='fixed inset-0 bg-black opacity-30' />

          {/* This element is to trick the browser into centering the modal contents. */}
          <span className='inline-block h-screen align-middle' aria-hidden='true'>
            &#8203;
          </span>
          <Transition.Child
            as={Fragment}
            enter='ease-out duration-300'
            enterFrom='opacity-0 scale-95'
            enterTo='opacity-100 scale-100'
            leave='ease-in duration-200'
            leaveFrom='opacity-100 scale-100'
            leaveTo='opacity-0 scale-95'
          >
            <div className='inline-block w-full max-w-md p-6 my-8 overflow-hidden text-left align-middle transition-all transform bg-white shadow-xl rounded-md '>
              <Dialog.Title as='h3' className='text-lg font-medium leading-6 text-gray-900'>
                {props.title}
              </Dialog.Title>
              <Dialog.Description className='text-grey-300'>{props.description}</Dialog.Description>
              <div className='mt-2'>
                {!props.textArea ? (
                  <input
                    type='text'
                    className='focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 min-w-0 rounded-md sm:text-sm p-3 '
                    value={props.inputValue}
                    onChange={onValueChange}
                  />
                ) : (
                  <textarea
                    className='focus:ring-blue-lm focus:border-blue-lm block w-full border-gray-200 border-2 min-w-0 rounded-md sm:text-sm p-3 h-48'
                    value={props.inputValue}
                    onChange={onValueChange}
                  />
                )}
              </div>

              <div className='mt-4'>
                <button
                  type='button'
                  className={`inline-flex justify-center px-4 py-2 text-sm font-medium ${
                    props.red ? 'text-red-900 bg-red-100 hover:bg-red-200' : 'text-blue-900 bg-blue-100 hover:bg-blue-200'
                  }  border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500`}
                  onClick={props.onClose}
                >
                  Cancel
                </button>
                <button
                  disabled={notValid}
                  type='button'
                  className={`inline-flex justify-center px-4 py-2  ml-3 text-sm font-medium text-white ${
                    props.red ? 'bg-red-600 hover:bg-red-700' : 'bg-blue-lm hover:bg-blue-dark'
                  } border border-transparent rounded-md  focus:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-blue-500 disabled:opacity-50 ${
                    notValid ? 'cursor-not-allowed' : ''
                  }`}
                  onClick={props.sendHandler}
                >
                  {props.saveText}
                </button>
              </div>
            </div>
          </Transition.Child>
        </div>
      </Dialog>
    </Transition>
  );
};

export default InputModal;
