import { Link, useParams } from 'react-router-dom';
import { Page } from 'models/page';
import PencilSvg from 'components/Svg/PencilSvg';

import ChildItemSvg from 'components/Svg/ChildItemSvg';

import PreviewLink from 'components/PreviewLink';
import StatusTag from 'components/StatusTag';

type Props = {
  page: Page;
};

const ChildPageItem = ({ page }: Props) => {
  const { businessName } = useParams<{ businessName: string }>();
  const isBYOB: boolean = true;

  return (
    <li>
      <div className='block bg-gray-50 hover:bg-gray-100 transition-all'>
        <div className='flex '>
          <div className='flex ml-4 flex-col flex-grow  py-4 sm:px-6 justify-between'>
            <div className='flex items-center mb-1'>
              <div
                className={`font-medium ${
                  page.requiresSpecialisation ? 'text-gray-400' : 'text-navy'
                } truncate my-1 flex items-start flex-col md:flex-row break-all`}
              >
                <p className='text-lg flex items-center mb-3 sm:text-xl'>
                  <span className='mr-1.5 text-gray-500 inline-block '>
                    <ChildItemSvg />
                  </span>
                  {page.title}
                </p>
                {page.custom && (
                  <span className=' px-3 py-0.5 rounded-full text-xs font-medium mb-2 text-blue-lm bg-blue-tint ml-2  md:ml-0'>Custom Page</span>
                )}
                {page.approval && <StatusTag approval={page.approval} />}
                {page.requiresSpecialisation && (
                  <span className='inline-flex items-center px-3 py-0.5 rounded-full text-xs font-medium mb-2 flex-grow-0 ml-2 md:ml-0 bg-gray-300 text-gray-800'>
                    Requires Specialisation
                  </span>
                )}
                {page.custom ? (
                  page.visibility ? (
                    <span className=' px-3 py-0.5 rounded-full text-xs font-medium mb-2 text-purple-600 bg-purple-300 ml-2  md:ml-0'>Visible</span>
                  ) : (
                    <span className=' px-3 py-0.5 rounded-full text-xs font-medium mb-2 text-purple-600 bg-purple-300 ml-2  md:ml-0'>Hidden</span>
                  )
                ) : null}
              </div>
            </div>
            <div className='sm:flex'>
              <p className='flex items-center text-sm text-gray-500'>{page.displaySlug}</p>
            </div>
          </div>
          <PreviewLink businessName={businessName} slug={page.slug} />
          <div className='sm:flex sm:justify-between'>
            {isBYOB ? (
              <div className='flex px-9 items-center bg-blue-tint h-full text-sm text-gray-500 sm:mt-0'>
                <Link
                  to={{
                    pathname: `/${businessName}/page-edit/${page.slug}`,
                    state: { id: page.id }
                  }}
                >
                  <span className='text-blue-lm'>
                    <PencilSvg outline={true} />
                  </span>
                </Link>
              </div>
            ) : (
              <div className='w-5 h-5' />
            )}
          </div>
        </div>
      </div>
    </li>
  );
};

export default ChildPageItem;
